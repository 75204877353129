import React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Header from "../components/Header";
import { Link } from "gatsby";

const About = ({ data }) => {
  const { _rawBody, contact, cover, instagram, facebook } = data.sanityAbout;

  return (
    <>
      <Header path="ABOUT" />
      <div className="aboutPage">
        <div style={{ marginTop: 120 }}>
          <div className="row">
            <div className="col-md-6 col-xl-6 order-md-2">
              <GatsbyImage
                image={getImage(cover.asset)}
                placeholder="none"
                alt="img"
              />
            </div>

            <div className="col-md-6 col-xl-6 order-md-1">
              <div className="row">
                <div className="col-sm-12 col-xl-10">
                  <h4 className="header-about">ABOUT</h4>
                </div>
                <div className="col-sm-12 col-xl-10">
                  <BlockContent className="text" blocks={_rawBody} />
                </div>
                <div className="col-sm-12 col-xl-10">
                  <h4 className="header-contact">CONTACT</h4>
                </div>
                <div className="col-sm-12 col-xl-10">
                  <p className="contact">{contact}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="row">
          <div className="col-5" style={{ marginRight: 40 }}>
            <p className="marca"> © DAVID RACCUGLIA</p>
          </div>

          <div className="col-5">
            <Link className="btn-social" href={instagram ? instagram : "#"}>
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.9">
                  <path
                    opacity="0.9"
                    d="M12.2 2.6C15.3 2.6 15.7 2.6 16.9 2.7C18 2.8 18.7 2.9 19.1 3.1C19.6 3.3 20 3.6 20.4 4C20.8 4.4 21.1 4.8 21.3 5.3C21.5 5.7 21.6 6.3 21.7 7.5C21.8 8.7 21.8 9.1 21.8 12.2C21.8 15.3 21.8 15.7 21.7 16.9C21.6 18 21.5 18.7 21.3 19.1C21.1 19.6 20.8 20 20.4 20.4C20 20.8 19.6 21.1 19.1 21.3C18.7 21.5 18.1 21.6 16.9 21.7C15.7 21.8 15.3 21.8 12.2 21.8C9.1 21.8 8.7 21.8 7.5 21.7C6.4 21.6 5.7 21.5 5.3 21.3C4.8 21.1 4.4 20.8 4 20.4C3.6 20 3.3 19.6 3.1 19.1C2.9 18.7 2.8 18.1 2.7 16.9C2.6 15.7 2.6 15.3 2.6 12.2C2.6 9.1 2.6 8.7 2.7 7.5C2.8 6.4 2.9 5.7 3.1 5.3C3.3 4.8 3.6 4.4 4 4C4.4 3.6 4.8 3.3 5.3 3.1C5.7 2.9 6.3 2.8 7.5 2.7C8.7 2.6 9.1 2.6 12.2 2.6ZM12.2 0.5C9 0.5 8.6 0.5 7.4 0.6C6.1 0.7 5.3 0.9 4.6 1.1C3.8 1.4 3.1 1.8 2.5 2.5C1.9 3.1 1.5 3.8 1.2 4.6C0.9 5.3 0.7 6.1 0.6 7.4C0.5 8.6 0.5 9 0.5 12.2C0.5 15.4 0.5 15.8 0.6 17C0.7 18.2 0.9 19.1 1.1 19.8C1.4 20.6 1.8 21.2 2.4 21.9C3 22.5 3.7 22.9 4.5 23.2C5.2 23.5 6.1 23.7 7.3 23.7C8.5 23.8 8.9 23.8 12.1 23.8C15.3 23.8 15.7 23.8 16.9 23.7C18.1 23.6 19 23.4 19.7 23.2C20.5 22.9 21.1 22.5 21.8 21.9C22.4 21.3 22.8 20.6 23.1 19.8C23.4 19.1 23.6 18.2 23.6 17C23.7 15.8 23.7 15.4 23.7 12.2C23.7 9 23.7 8.6 23.6 7.4C23.5 6.2 23.3 5.3 23.1 4.6C22.8 3.8 22.4 3.2 21.8 2.5C21.2 1.9 20.5 1.5 19.7 1.2C19 0.9 18.1 0.7 16.9 0.7C15.8 0.5 15.4 0.5 12.2 0.5Z"
                    fill="#010101"
                  />
                  <path
                    opacity="0.9"
                    d="M12.2002 6.19995C8.9002 6.19995 6.2002 8.89995 6.2002 12.2C6.2002 15.5 8.9002 18.2 12.2002 18.2C15.5002 18.2 18.2002 15.5 18.2002 12.2C18.2002 8.89995 15.5002 6.19995 12.2002 6.19995ZM12.2002 16.1C10.1002 16.1 8.3002 14.4 8.3002 12.2C8.3002 9.99995 10.0002 8.29995 12.2002 8.29995C14.4002 8.29995 16.1002 9.99995 16.1002 12.2C16.1002 14.4 14.3002 16.1 12.2002 16.1Z"
                    fill="#010101"
                  />
                  <path
                    opacity="0.9"
                    d="M18.4 7.39998C19.1732 7.39998 19.8 6.77317 19.8 5.99998C19.8 5.22678 19.1732 4.59998 18.4 4.59998C17.6268 4.59998 17 5.22678 17 5.99998C17 6.77317 17.6268 7.39998 18.4 7.39998Z"
                    fill="#010101"
                  />
                </g>
              </svg>
            </Link>
            <Link className="btn-social" href={facebook ? facebook : "#"}>
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5999 12.8V23H13.8999V12.7H16.7999C16.8999 11.5 16.9999 10.4 17.0999 9.20001H13.8999C13.8999 8.50001 13.8999 7.80001 13.8999 7.20001C13.8999 6.50001 14.1999 6.10001 14.9999 6.10001C15.6999 6.10001 16.3999 6.10001 17.0999 6.10001V2.50001C15.7999 2.50001 14.5999 2.40001 13.4999 2.50001C10.9999 2.70001 9.6999 3.90001 9.6999 6.50001C9.6999 7.40001 9.6999 8.30001 9.6999 9.20001H8.3999H7.3999C7.3999 10 7.3999 10.6 7.3999 11.2C7.3999 12.8 7.3999 12.8 8.9999 12.8C9.0999 12.8 9.2999 12.8 9.5999 12.8Z"
                  fill="#010101"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

export const query = graphql`
  query MyAboutQuery {
    sanityAbout {
      contact
      instagram
      facebook
      cover {
        ... on SanityImage {
          asset {
            gatsbyImageData(height: 1218, width: 914, placeholder: NONE)
          }
        }
      }
      body {
        children {
          text
        }
      }
      _rawBody
    }
  }
`;
